import SectionHeader from '../components/sections/header';
import SectionBreadcrumbs from '../components/sections/breadcrumbs';
import SectionService from '../components/sections/service';
import SectionPeopleSlider from '../components/sections/people-slider';
import SectionFooter from '../components/sections/footer';

import React from 'react';
import { graphql } from 'gatsby';

import { IPage } from '../models/page.model';
import { IService } from '../models/service.model';
import getPageConfigFromSections from '../utils/get-page-config-from-sections';

import MainLayout from '../layouts/main-layout';
import SectionFactory from '../components/hoc/section-factory';
import UpwardsButton from '../components/molecules/upwards-button';

interface IServiceProps {
    readonly data: {
        page: IPage;
        service: IService;
    };
}

const Service: React.FC<IServiceProps> = ({ data }) => {
    const { page, service } = data;
    const sections = page.sections;
    const { mainSectionIndex } = getPageConfigFromSections(sections);

    const sectionsWithData = sections.map((section) => {
        if (section.type === 'service') {
            return {
                ...section,
                extendedItems: {
                    service: service,
                },
            };
        }
        if (['people-slider'].includes(section.type)) {
            return {
                ...section,
                extendedItems: {
                    service: service,
                },
            };
        }
        return section;
    });

    return (
        <MainLayout>
            {sectionsWithData.map((section, index) => {
                return (
                    <SectionFactory
                        key={`section-${section.type}-${section.sectionId}`}
                        SectionComponent={sectionComponents[section.type]}
                        section={section}
                        TitleTag={mainSectionIndex === index ? 'h1' : 'h4'}
                    />
                );
            })}
            <UpwardsButton />
        </MainLayout>
    );
};

export const query = graphql`
    query ($pageId: Int!, $serviceId: Int!, $locale: String!) {
        page(pageId: { eq: $pageId }) {
            sections {
                ...sectionFields
            }
        }
        service(serviceId: { eq: $serviceId }, locale: { eq: $locale }) {
            ...serviceFields
        }
    }
`;

export default Service;

export { Head } from '@alterpage/gatsby-plugin-alterpress-page-creator';



const sectionComponents: Record<string, any> = {
    'header': SectionHeader,
    'breadcrumbs': SectionBreadcrumbs,
    'service': SectionService,
    'people-slider': SectionPeopleSlider,
    'footer': SectionFooter,
};