import React from "react";
import {Image} from "@alterpage/gatsby-plugin-image";

import { container, headerWrapper, image, title, contentContainer, contentWrapper, descriptionMarkdown, faqTitle} from './service.module.scss';

import {IService} from "../../models/service.model";
import {ISection} from "../../models/section.model";

import Section from "../hoc/section";
import Title from "../atoms/title";
import Markdown from "../hoc/markdown";

export interface IServiceSection extends ISection {
    extendedItems: {
        service: IService;
    };
}

interface IServiceProps {
    className?: string;
    section: IServiceSection;
    TitleTag?: React.ElementType;
}


const Service: React.FC<IServiceProps> = ({ className = '', section, TitleTag = 'h1' }) => {
    const { service } = section.extendedItems;
    const { sectionId, css, style } = section;

    return (
        <Section
            sectionId={sectionId}
            className={`${className} ${container}`}
            css={css}
            style={style}
        >
            <div className={headerWrapper}>
                <Title Tag={TitleTag} className={title}>
                    {service.name}
                </Title>
                <Image media={service.media} className={image} />
            </div>
            <div className={contentContainer}>
                <div className={contentWrapper}>
                    {service.description && (
                        <Markdown className={descriptionMarkdown}>
                            {service.description}
                        </Markdown>
                    )}
                    {service.faq && (
                        <>
                            <Title Tag={'h2'} className={faqTitle}>
                                FAQ
                            </Title>
                            <Markdown>
                                {service.faq}
                            </Markdown>
                        </>
                    )}
                </div>
            </div>
        </Section>
    )
}

export default Service;